/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { ExclamationCircleFilled, MoreOutlined, PlusCircleFilled } from "@ant-design/icons";
import { App, Button, Card, Col, Dropdown, Row, Space, Table, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { requestGetUserList } from "../../../services/admin.service";
import dayjs from "dayjs";
import { HiOutlinePencil, HiOutlineTrash } from "react-icons/hi2";
import SearchInput from "../../../components/SearchInput";
import UserFormModal from "./components/UserFormModal";
import { useSearchParams } from "react-router-dom";
import parsePhoneNumber from "libphonenumber-js";
import { ID, MY, TW } from "country-flag-icons/react/3x2";
import Typography from "antd/es/typography/Typography";

const columns = [
	{
		title: "Nama",
		dataIndex: "name",
		key: "name",
		sorter: true,
	},
	{
		title: "Email",
		dataIndex: "email",
		key: "email",
	},
	{
		title: "No. Telp",
		dataIndex: "phone",
		key: "phone",
		render: (phone) => {
			if (phone) {
				const phoneNumber = parsePhoneNumber(`+${phone}`, "ID");
				return (
					<div
						style={{
							display: "flex",
							gap: 4,
							alignItems: "center",
							justifyContent: "flex-start",
						}}>
						{phoneNumber.country === "ID" ? (
							<ID
								title="Indonesia"
								style={{
									height: 23.6,
									borderRadius: 4,
									border: "1px solid #CECECEFF",
								}}
							/>
						) : phoneNumber.country === "MY" ? (
							<MY
								title="Malaysia"
								style={{
									height: 23.6,
									borderRadius: 4,
									border: "1px solid #CECECEFF",
								}}
							/>
						) : phoneNumber.country === "TW" ? (
							<TW
								title="Taiwan"
								style={{
									height: 23.6,
									borderRadius: 4,
									border: "1px solid #CECECEFF",
								}}
							/>
						) : (
							<ID
								title="Indonesia"
								style={{
									height: 23.6,
									borderRadius: 4,
									border: "1px solid #CECECEFF",
								}}
							/>
						)}
						<Tag>
							<Typography.Text copyable={{ text: phone }}>
								{phoneNumber.formatInternational()}
							</Typography.Text>
						</Tag>
					</div>
				);
			} else {
				return "-";
			}
		},
	},
	{
		title: "Jenis",
		dataIndex: "role",
		key: "role",
		render: (role) => {
			if (role === "ADMIN") {
				return <Tag color="red">Admin</Tag>;
			} else if (role === "TEACHER") {
				return <Tag color="blue">Guru</Tag>;
			} else if (role === "STUDENT") {
				return <Tag color="green">Siswa</Tag>;
			} else if (role === "PARENT") {
				return <Tag color="green">Wali</Tag>;
			} else if (role === "MASTER") {
				return <Tag color="default">Master</Tag>;
			} else {
				return <Tag color="default">Pengguna</Tag>;
			}
		},
		filters: [
			{
				text: "Admin",
				value: "ADMIN",
			},
			{
				text: "Guru",
				value: "TEACHER",
			},
			{
				text: "Siswa",
				value: "STUDENT",
			},
			{
				text: "Wali",
				value: "PARENT",
			},
		],
	},
	{
		title: "Bergabung pada",
		dataIndex: "createdAt",
		key: "createdAt",
		render: (text) => {
			return dayjs(text).format("DD MMM YYYY HH:mm");
		},
	},
	{
		title: "Aksi",
		dataIndex: "actions",
		key: "actions",
		align: "right",
	},
];

const otherButtons = [
	{
		key: "edit",
		label: "Edit",
		icon: <HiOutlinePencil />,
	},
	{
		type: "divider",
	},
	{
		key: "delete",
		label: "Hapus",
		icon: <HiOutlineTrash />,
		danger: true,
	},
];

const UserPage = () => {
	const [searchParams, setSearchParams] = useSearchParams();

	const { notification, modal } = App.useApp();

	// SEARCH, FILTER, PAGINATION
	const [keyword, setKeyword] = useState("");
	const [filter, setFilter] = useState({});
	const [sorter, setSorter] = useState({
		_id: "desc",
	});
	const [total, setTotal] = useState(0);
	const [page, setPage] = useState(1);
	const [limit, setLimit] = useState(10);

	const handleSearch = (value) => {
		setKeyword(value.trim());
		setPage(1);
	};

	const handlePaginate = (pagination, filters, sorter) => {
		setPage(pagination.current);
		setLimit(pagination.pageSize);

		// UPDATE FILTER
		if (filters) {
			const additionalFilter = {};
			Object.keys(filters).forEach((key) => {
				if (filters[key]) {
					additionalFilter[key] = filters[key].join("|");
				} else {
					additionalFilter[key] = "";
				}
			});

			setFilter({
				...filter,
				...additionalFilter,
			});
		} else {
			const tempFilter = {};
			setFilter(tempFilter);
		}

		// UPDATE SORTER
		if (sorter && sorter.field) {
			setSorter({
				[sorter.field]: sorter.order === "ascend" ? "asc" : "desc",
			});
		} else {
			setSorter({
				_id: "desc",
			});
		}
	};
	// SEARCH, FILTER, PAGINATION

	const [users, setUsers] = useState([]);
	const [mappedUsers, setMappedUsers] = useState([]);
	const [isLoading, setLoading] = useState(false);

	const [user, setUser] = useState(null);
	const [isUserFormVisible, setUserFormVisible] = useState(false);

	useEffect(() => {
		setSearchParams({ keyword: keyword, page: page, limit: limit });

		fetchUserList();
	}, [keyword, filter, page, limit]);

	useEffect(() => {
		if (searchParams.has("keyword")) {
			setKeyword(searchParams.get("keyword"));
		}
		if (searchParams.has("page")) {
			setPage(parseInt(searchParams.get("page")));
		}
		if (searchParams.has("limit")) {
			setLimit(parseInt(searchParams.get("limit")));
		}
	}, []);

	const fetchUserList = () => {
		setLoading(true);
		let tempFilter = "";
		if (filter) {
			tempFilter = Object.keys(filter)
				.map((key) => `${key}:${filter[key]}`)
				.filter(
					(f) => f.split(":")[1] !== "" && f.split(":")[1] !== undefined && f.split(":")[1] !== null
				)
				.join(",");
		}

		let tempSorter = "";
		if (sorter) {
			tempSorter = Object.keys(sorter)
				.map((key) => `${key}:${sorter[key]}`)
				.filter(
					(s) => s.split(":")[1] !== "" && s.split(":")[1] !== undefined && s.split(":")[1] !== null
				)
				.join(",");
		}

		requestGetUserList({
			page: page,
			limit: limit,
			order: tempSorter ?? "_id:desc",
			keyword: keyword ?? "",
			filter: tempFilter ?? "",
		})
			.then((response) => {
				setUsers(response.data.data);
				updateMappedUser(response.data.data);
				setTotal(response.data.pagination.total);
			})
			.catch((error) => {
				if (error.response && error.response.data) {
					notification["error"]({
						message: "Kesalahan!",
						description: error.response.data.message,
					});
				} else {
					notification["error"]({
						message: "Kesalahan!",
						description: error.message,
					});
				}
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const updateMappedUser = (data) => {
		setMappedUsers(
			data.map((d) => ({
				key: d._id,
				name: d.name,
				email: d.email,
				phone: d.phone,
				role: d.role,
				actions: (
					<Space>
						<Dropdown
							trigger={["click"]}
							menu={{
								onClick: (e) => {
									if (e.key === "edit") {
										handleEditButton(d);
									} else if (e.key === "delete") {
										modal.confirm({
											title: "Sebentar",
											icon: <ExclamationCircleFilled />,
											content:
												"Anda yakin akan menghapus guru ini? Tindakan ini tidak dapat dibatalkan. Semua data terkait akan terputus.",
											okText: "Ya",
											okType: "danger",
											cancelText: "Tidak",
											onOk() {
												handleDeleteButton(d._id);
											},
											onCancel() {},
										});
									}
								},
								items: otherButtons,
							}}
							placement="bottomRight"
							arrow>
							<Button type="default" shape="circle" size="small" icon={<MoreOutlined />} />
						</Dropdown>
					</Space>
				),
			}))
		);
	};

	const handleAddButton = () => {
		setUserFormVisible(true);
	};

	const handleEditButton = (user) => {
		setUser(user);
		setUserFormVisible(true);
	};

	const handleDeleteButton = (id) => {
		// setLoading(true);
		// deleteUser(id)
		// 	.then((response) => {
		// 		if (response.data.code === 200) {
		// 			notification["success"]({
		// 				message: "Good job!",
		// 				description: response.data.message,
		// 			});
		// 			fetchUserList();
		// 		}
		// 	})
		// 	.catch((error) => {
		// 		if (error.response && error.response.data) {
		// 			notification["error"]({
		// 				message: "Kesalahan!",
		// 				description: error.response.data.message,
		// 			});
		// 		} else {
		// 			notification["error"]({
		// 				message: "Kesalahan!",
		// 				description: error.message,
		// 			});
		// 		}
		// 	})
		// 	.finally(() => {
		// 		setLoading(false);
		// 	});
	};

	const handleOnCloseUserForm = () => {
		setUser(null);
		setUserFormVisible(false);
	};

	const handleOnSuccessUserForm = () => {
		setUser(null);
		setUserFormVisible(false);
		fetchUserList();
	};

	return (
		<>
			<UserFormModal
				isLoading={isLoading}
				isVisible={isUserFormVisible}
				onLoading={(v) => setLoading(v)}
				user={user}
				onClose={handleOnCloseUserForm}
				onSuccess={handleOnSuccessUserForm}
			/>
			<Card
				bordered={false}
				title="Daftar Pengguna"
				extra={
					<Space>
						<SearchInput
							placeholder="NIP atau nama guru"
							onSearch={handleSearch}
							defaultValue={keyword}
							isLoading={isLoading}
						/>
						<Button icon={<PlusCircleFilled />} type="primary" onClick={handleAddButton}>
							<span className="ant-btn-text">Tambah</span>
						</Button>
					</Space>
				}>
				<div>
					<Table
						bordered
						columns={columns}
						loading={isLoading}
						dataSource={mappedUsers}
						pagination={{
							current: page,
							total: total,
							position: "bottomRight",
							pageSizeOptions: ["10", "20", "50", "100"],
							showSizeChanger: true,
							locale: { items_per_page: "item/hal" },
						}}
						onChange={handlePaginate}
					/>
				</div>
			</Card>
		</>
	);
};

export default UserPage;
