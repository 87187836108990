/* eslint-disable react-hooks/exhaustive-deps */
import {
	App,
	Button,
	Card,
	Col,
	Descriptions,
	Dropdown,
	Modal,
	Popconfirm,
	Radio,
	Row,
	Select,
	Space,
	Spin,
	Table,
	Tag,
	Tooltip,
	Typography,
} from "antd";
import {
	CheckCircleFilled,
	CloseCircleFilled,
	DownloadOutlined,
	PlusCircleFilled,
	UpCircleFilled,
} from "@ant-design/icons";
import React, { useEffect } from "react";
import writeXlsxFile from "write-excel-file";
import {
	requestGetEventList,
	requestGetFeeList,
	requestGetInvoiceList,
	requestUnassignTeacherFromClassroom,
	requestUnassignTeacherFromLesson,
	requestUnenrollStudentsFromClassroom,
	showClassroom,
} from "../../../../services/admin.service";
import TeacherAssignFormModal from "../../../../components/TeacherAssignFormModal";
import StudentEnrollFormModal from "../../../../components/StudentEnrollFormModal";
import { HiClipboardDocumentCheck, HiClipboardDocumentList } from "react-icons/hi2";
import dayjs from "dayjs";
import ClassroomDailyReportModal from "./ClassroomDailyReportModal";
import ClassroomPromotionFormModal from "./ClassroomPromotionFormModal";

dayjs.extend(require("dayjs/plugin/isSameOrAfter"));
dayjs.extend(require("dayjs/plugin/isSameOrBefore"));

export const ClassroomDetailModal = ({
	id,
	year,
	isVisible,
	isLoading,
	onLoading,
	onClose,
	onSuccess,
	activeYear,
}) => {
	const { notification } = App.useApp();
	const studentColumns = [
		{
			title: "No",
			dataIndex: "order",
			fixed: "left",
			key: "order",
			width: 36,
		},
		{
			title: "NISN",
			dataIndex: "number",
			key: "number",
			width: 100,
			fixed: "left",
			render: (text, record) => {
				return (
					<Typography.Text
						style={{
							fontFamily: "monospace",
							fontWeight: "bold",
							whiteSpace: "nowrap",
						}}
						copyable>
						{record.number}
					</Typography.Text>
				);
			},
		},
		{
			title: "Nama",
			dataIndex: "name",
			key: "name",
			fixed: "left",
			render: (text, record) => {
				return (
					<div
						style={{
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
							gap: 8,
						}}>
						<Typography.Text>{text}</Typography.Text>

						<Tooltip title="Keluarkan dari kelas">
							<Popconfirm
								placement="topRight"
								title={`Anda yakin akan mengeluarkan ${text}?`}
								onConfirm={(e) => handleUnenrollStudent(record.key)}
								okText="Ya"
								cancelText="Tdk">
								<Button
									type="link"
									shape="default"
									size="small"
									icon={<CloseCircleFilled />}
									danger
								/>
							</Popconfirm>
						</Tooltip>
					</div>
				);
			},
		},
	];

	const lessonColumns = [
		{
			title: "Matpel",
			dataIndex: "name",
			key: "name",
		},
		{
			title: "Pengajar",
			dataIndex: "teachers",
			key: "teachers",
		},
	];

	const [classroom, setClassroom] = React.useState(null);
	const [isTeacherAssignVisible, setTeacherAssignVisible] = React.useState(false);
	const [isStudentEnrollVisible, setStudentEnrollVisible] = React.useState(false);
	const [isDailyReportVisible, setDailyReportVisible] = React.useState(false);
	const [columns, setColumns] = React.useState(studentColumns);

	const [students, setStudents] = React.useState([]);
	const [lessons, setLessons] = React.useState([]);
	const [mappedLessons, setMappedLessons] = React.useState([]);
	const [mappedStudents, setMappedStudents] = React.useState([]);

	const [lesson, setLesson] = React.useState(null);
	const [assignType, setAssignType] = React.useState("");
	const [mode, setMode] = React.useState("student");
	const [downloadMode, setDownloadMode] = React.useState("month");
	const [downloadMonth, setDownloadMonth] = React.useState(dayjs().format("YYYY-MM"));
	const [events, setEvents] = React.useState([]);

	const [invoices, setInvoices] = React.useState([]);

	const [selectedStudentIds, setSelectedStudentIds] = React.useState([]);
	const [isPromotionFormVisible, setPromotionFormVisible] = React.useState(false);

	useEffect(() => {
		if (id) {
			fetchClassroom();
		} else {
			setClassroom(null);
			setAssignType("");
		}
		setMode("");
	}, [id]);

	useEffect(() => {
		if (classroom) {
			updateMappedItems();
		}
	}, [classroom]);

	useEffect(() => {
		setColumns(studentColumns);
		if (mode === "attendance") {
			fetchClassroomAttendances();
		} else if (mode === "invoice") {
			fetchFees();
		} else {
			setColumns(studentColumns);
		}
	}, [mode]);

	const updateMappedItems = (invoices = null, attendances = null) => {
		const lessons = classroom.lessons;
		const students = classroom.students;

		setLessons(lessons);
		setStudents(students);

		const mappedLessons = lessons.map((lesson) => {
			const teachers = lesson.teachers;

			return {
				key: lesson._id,
				name: lesson.subject.name,
				teachers: (
					<>
						<Space wrap>
							{teachers.map((teacher, index) => {
								return (
									<Space size={1} direction="horizontal" key={index}>
										<Typography.Text>
											{teacher.name}
											<Tag
												color="blue"
												style={{
													marginLeft: 4,
													marginRight: 0,
												}}>
												{teacher.type === "MAIN"
													? "Utama"
													: teacher.type === "ASSISTANT"
													? "Asisten"
													: "Pengganti"}
											</Tag>
										</Typography.Text>
										<Popconfirm
											placement="topRight"
											title={`Anda yakin akan membebastugaskan ${teacher.name}?`}
											onConfirm={(e) =>
												handleUnassignTeacherButton(lesson._id, teacher._id, lesson)
											}
											okText="Yes"
											cancelText="No">
											<Tooltip title="Bebastugaskan Pengajar">
												<Button
													type="link"
													shape="default"
													size="small"
													icon={<CloseCircleFilled />}
													danger
												/>
											</Tooltip>
										</Popconfirm>
									</Space>
								);
							})}

							<Tooltip title="Tugaskan Pengajar">
								<Button
									type="dashed"
									shape="circle"
									size="small"
									onClick={() => handleAssignTeacherButton(lesson, "")}>
									<PlusCircleFilled />
								</Button>
							</Tooltip>
						</Space>
					</>
				),
			};
		});

		setMappedLessons(mappedLessons);

		const mappedStudents = students
			.sort((a, b) => ("" + a.name).localeCompare(b.name))
			.map((student, index) => {
				return {
					key: student._id,
					order: index + 1,
					number: student.number,
					name: student.name,
				};
			});

		setMappedStudents(mappedStudents);
	};

	const fetchClassroom = () => {
		onLoading(true);
		showClassroom(id)
			.then((response) => {
				const classroom = response.data.data;

				setClassroom(classroom);
			})
			.catch((error) => {})
			.finally(() => {
				onLoading(false);
			});
	};

	const fetchFees = () => {
		onLoading(true);

		requestGetFeeList({
			order: "type:asc",
			limit: 1000,
		})
			.then((response) => {
				const fees = response.data.data;

				fetchInvoices(fees);
			})
			.catch((error) => {
				if (error.response && error.response.data) {
					notification.error({
						message: "Kesalahan!",
						description: error.response.data.message,
					});
				} else {
					notification.error({
						message: "Kesalahan!",
						description: error.message,
					});
				}
			})
			.finally(() => {
				onLoading(false);
			});
	};

	const fetchInvoices = (fees) => {
		onLoading(true);

		requestGetInvoiceList({
			limit: 1000,
			filter: `year:${year._id},student:${classroom.students
				.map((student) => student._id)
				.join("|")}`,
		})
			.then((response) => {
				const invoices = response.data.data;

				const cols = [...studentColumns];
				fees.forEach((fee) => {
					if (fee.type === "MONTHLY") {
						// generate from beginning of the year until end of the year
						const start = dayjs(year.startedAt);
						const end = dayjs(year.endedAt);

						const months = [];
						let current = start;
						while (current.isBefore(end)) {
							months.push(current.format("MMMM"));
							current = current.add(1, "month");
						}

						months.forEach((month) => {
							cols.push({
								title: fee.name + " " + month.substring(0, 3),
								dataIndex: fee._id,
								key: fee._id,
								align: "center",
								width: 100,
								render: (text, record) => {
									const invoice = invoices.find(
										(invoice) =>
											dayjs(invoice.issuedAt).format("MMMM") === month &&
											invoice.student._id === record.key &&
											invoice.items.map((item) => item.fee._id).includes(fee._id)
									);

									if (invoice) {
										if (invoice.status === "PAID") {
											return (
												<Tag color="green" style={{ fontWeight: "normal" }}>
													Lunas
												</Tag>
											);
										} else {
											return (
												<Tag color="red" style={{ fontWeight: "normal" }}>
													Belum Lunas
												</Tag>
											);
										}
									} else {
										return (
											<Tag color="default" style={{ fontWeight: "normal" }}>
												Belum ada
											</Tag>
										);
									}
								},
							});
						});
					} else if (fee.type === "SEMESTRIAL") {
						const semesters = year.semesters;
						semesters.forEach((semester) => {
							cols.push({
								title: fee.name + " " + semester,
								dataIndex: fee._id,
								key: fee._id,
								align: "center",
								width: 100,
								render: (text, record) => {
									const invoice = invoices.find(
										(invoice) =>
											dayjs(invoice.issuedAt).isSameOrAfter(semester.startedAt) &&
											dayjs(invoice.issuedAt).isSameOrBefore(semester.endedAt) &&
											invoice.student._id === record.key &&
											invoice.items.map((item) => item.fee._id).includes(fee._id)
									);

									if (invoice) {
										if (invoice.status === "PAID") {
											return (
												<Tag color="green" style={{ fontWeight: "normal" }}>
													Lunas
												</Tag>
											);
										} else {
											return (
												<Tag color="red" style={{ fontWeight: "normal" }}>
													Belum Lunas
												</Tag>
											);
										}
									} else {
										return (
											<Tag color="default" style={{ fontWeight: "normal" }}>
												Belum ada
											</Tag>
										);
									}
								},
							});
						});
					} else if (fee.type === "PERIODICAL") {
						cols.push({
							title: fee.name + " " + year.name,
							dataIndex: fee._id,
							key: fee._id,
							align: "center",
							width: 100,
							render: (text, record) => {
								const invoice = invoices.find(
									(invoice) =>
										dayjs(invoice.issuedAt).isSameOrAfter(year.startedAt) &&
										dayjs(invoice.issuedAt).isSameOrBefore(year.endedAt) &&
										invoice.student._id === record.key &&
										invoice.items.map((item) => item.fee._id).includes(fee._id)
								);

								if (invoice) {
									if (invoice.status === "PAID") {
										return (
											<Tag color="green" style={{ fontWeight: "normal" }}>
												Lunas
											</Tag>
										);
									} else {
										return (
											<Tag color="red" style={{ fontWeight: "normal" }}>
												Belum Lunas
											</Tag>
										);
									}
								} else {
									return (
										<Tag color="default" style={{ fontWeight: "normal" }}>
											Belum ada
										</Tag>
									);
								}
							},
						});
					} else if (fee.type === "ONCE") {
						cols.push({
							title: fee.name,
							dataIndex: fee._id,
							key: fee._id,
							align: "center",
							width: 100,
							render: (text, record) => {
								const invoice = invoices.find(
									(invoice) =>
										invoice.student._id === record.key &&
										invoice.items.map((item) => item.fee._id).includes(fee._id)
								);

								if (invoice) {
									if (invoice.status === "PAID") {
										return (
											<Tag color="green" style={{ fontWeight: "normal" }}>
												Lunas
											</Tag>
										);
									} else {
										return (
											<Tag color="red" style={{ fontWeight: "normal" }}>
												Belum Lunas
											</Tag>
										);
									}
								} else {
									return (
										<Tag color="default" style={{ fontWeight: "normal" }}>
											Belum ada
										</Tag>
									);
								}
							},
						});
					}
				});

				setColumns(cols);
			})
			.catch((error) => {
				if (error.response && error.response.data) {
					notification.error({
						message: "Kesalahan!",
						description: error.response.data.message,
					});
				} else {
					notification.error({
						message: "Kesalahan!",
						description: error.message,
					});
				}
			})
			.finally(() => {
				onLoading(false);
			});
	};

	const fetchClassroomAttendances = () => {
		onLoading(true);

		requestGetEventList({
			order: "startedAt:asc",
			limit: 1000,
			filter: `type:CLASSROOM,reference:${classroom._id},start:${year.startedAt},end:${year.endedAt}`,
		})
			.then((response) => {
				const events = response.data.data;
				const cols = [...studentColumns];
				const semesters = year.semesters;

				setEvents(events);

				for (const semester of semesters) {
					const semesterCols = [];
					const monthInSemester = dayjs(semester.endedAt).diff(semester.startedAt, "month") + 1;
					const semesterMonths = [];

					for (let i = 0; i < monthInSemester; i++) {
						semesterMonths.push(dayjs(semester.startedAt).add(i, "month").format("MMMM YYYY"));
					}

					for (let month of semesterMonths) {
						const monthCols = [];
						const monthEvents = events.filter(
							(event) => dayjs(event.startedAt).format("MMMM YYYY") === month
						);

						// PUSH TO MONTHCOLS
						for (let event of monthEvents) {
							monthCols.push({
								title: dayjs(event.startedAt).format("D"),
								dataIndex: event._id,
								key: event._id,
								align: "center",
								width: 40,
								render: (text, record) => {
									const attendance = event.report.attendees.find(
										(attendee) => attendee.referenceId === record.key
									);

									if (attendance) {
										if (attendance.status === "PRESENT") {
											return (
												<Tooltip title="Hadir" placement="top">
													<Tag
														color="green"
														style={{
															fontWeight: "normal",
															marginInlineEnd: 0,
															width: 25,
															textAlign: "center",
														}}>
														H
													</Tag>
												</Tooltip>
											);
										} else if (attendance.status === "ABSENT") {
											return (
												<Tooltip title="Tidak Hadir" placement="top">
													<Tag
														color="red"
														style={{
															fontWeight: "normal",
															marginInlineEnd: 0,
															width: 25,
															textAlign: "center",
														}}>
														A
													</Tag>
												</Tooltip>
											);
										} else if (
											attendance.status === "EXCUSED" &&
											attendance?.permission?.type === "ILLNESS"
										) {
											return (
												<Tooltip title="Izin Sakit" placement="top">
													<Tag
														color="blue"
														style={{
															fontWeight: "normal",
															marginInlineEnd: 0,
															width: 25,
															textAlign: "center",
														}}>
														S
													</Tag>
												</Tooltip>
											);
										} else if (
											attendance.status === "EXCUSED" &&
											attendance?.permission?.type === "LEAVE"
										) {
											return (
												<Tooltip title="Izin Pergi" placement="top">
													<Tag
														color="purple"
														style={{
															fontWeight: "normal",
															marginInlineEnd: 0,
															width: 25,
															textAlign: "center",
														}}>
														I
													</Tag>
												</Tooltip>
											);
										} else if (attendance.status === "LATE") {
											return (
												<Tooltip title="Terlambat" placement="top">
													<Tag
														color="orange"
														style={{
															fontWeight: "normal",
															marginInlineEnd: 0,
															width: 25,
															textAlign: "center",
														}}>
														T
													</Tag>
												</Tooltip>
											);
										} else {
											return (
												<Tooltip title="Belum diisi" placement="top">
													<Tag
														color="default"
														style={{
															fontWeight: "normal",
															marginInlineEnd: 0,
															width: 25,
															textAlign: "center",
														}}>
														-
													</Tag>
												</Tooltip>
											);
										}
									} else {
										return (
											<Tooltip title="Belum diisi" placement="top">
												<Tag
													color="default"
													style={{
														fontWeight: "normal",
														marginInlineEnd: 0,
														width: 25,
														textAlign: "center",
													}}>
													?
												</Tag>
											</Tooltip>
										);
									}
								},
							});
						}
						// PUSH TO MONTHCOLS

						if (monthCols.length > 0) {
							// PUSH TO SEMESTERCOLS
							semesterCols.push({
								title: `${month} (${monthCols.length})`,
								dataIndex: month,
								key: month,
								width: 40,
								align: "center",
								children: monthCols,
							});
							// PUSH TO SEMESTERCOLS
						}
					}

					// ADD SUMMARY PER SEMESTER
					const semesterEvents = events.filter(
						(event) =>
							dayjs(event.startedAt).isSameOrAfter(semester.startedAt) &&
							dayjs(event.startedAt).isSameOrBefore(semester.endedAt)
					);

					semesterCols.push({
						title: "Keterangan",
						dataIndex: "summary_" + semester._id,
						key: "summary_" + semester._id,
						align: "center",
						width: 40,
						children: [
							{
								title: <Tooltip title="Hadir">H</Tooltip>,
								dataIndex: "present_" + semester._id,
								key: "present_" + semester._id,
								align: "center",
								width: 40,
								render: (text, record) => {
									const present = semesterEvents.filter((event) =>
										event.report.attendees.find(
											(attendee) =>
												attendee.referenceId === record.key && attendee.status === "PRESENT"
										)
									);

									return <Tooltip title="Hadir">{present.length}</Tooltip>;
								},
							},
							{
								title: <Tooltip title="Izin Sakit">S</Tooltip>,
								dataIndex: "illness_" + semester._id,
								key: "illness_" + semester._id,
								align: "center",
								width: 40,
								render: (text, record) => {
									const sick = semesterEvents.filter((event) =>
										event.report.attendees.find(
											(attendee) =>
												attendee.referenceId === record.key &&
												attendee.status === "EXCUSED" &&
												attendee?.permission?.type === "ILLNESS"
										)
									);

									return <Tooltip title="Sakit">{sick.length}</Tooltip>;
								},
							},
							{
								title: <Tooltip title="Izin Pergi">I</Tooltip>,
								dataIndex: "excused_" + semester._id,
								key: "excused_" + semester._id,
								align: "center",
								width: 40,
								render: (text, record) => {
									const excused = semesterEvents.filter((event) =>
										event.report.attendees.find(
											(attendee) =>
												attendee.referenceId === record.key &&
												attendee.status === "EXCUSED" &&
												attendee?.permission?.type === "LEAVE"
										)
									);

									return <Tooltip title="Izin">{excused.length}</Tooltip>;
								},
							},
							{
								title: <Tooltip title="Tidak hadir">A</Tooltip>,
								dataIndex: "absent_" + semester._id,
								key: "absent_" + semester._id,
								align: "center",
								width: 40,
								render: (text, record) => {
									const absent = semesterEvents.filter((event) =>
										event.report.attendees.find(
											(attendee) =>
												attendee.referenceId === record.key && attendee.status === "ABSENT"
										)
									);

									return <Tooltip title="Tidak hadir">{absent.length}</Tooltip>;
								},
							},
							{
								title: <Tooltip title="Terlambat">T</Tooltip>,
								dataIndex: "late_" + semester._id,
								key: "late_" + semester._id,
								align: "center",
								width: 40,
								render: (text, record) => {
									const late = semesterEvents.filter((event) =>
										event.report.attendees.find(
											(attendee) =>
												attendee.referenceId === record.key && attendee.status === "LATE"
										)
									);

									return <Tooltip title="Terlambat">{late.length}</Tooltip>;
								},
							},
							// total
							{
								title: <Tooltip title="Total">#</Tooltip>,
								dataIndex: "total_" + semester._id,
								key: "total_" + semester._id,
								align: "center",
								width: 40,
								render: (text, record) => {
									const present = semesterEvents.filter((event) =>
										event.report.attendees.find(
											(attendee) =>
												attendee.referenceId === record.key && attendee.status === "PRESENT"
										)
									);

									const sick = semesterEvents.filter((event) =>
										event.report.attendees.find(
											(attendee) =>
												attendee.referenceId === record.key &&
												attendee.status === "EXCUSED" &&
												attendee?.permission?.type === "ILLNESS"
										)
									);

									const excused = semesterEvents.filter((event) =>
										event.report.attendees.find(
											(attendee) =>
												attendee.referenceId === record.key &&
												attendee.status === "EXCUSED" &&
												attendee?.permission?.type === "LEAVE"
										)
									);

									const absent = semesterEvents.filter((event) =>
										event.report.attendees.find(
											(attendee) =>
												attendee.referenceId === record.key && attendee.status === "ABSENT"
										)
									);

									const late = semesterEvents.filter((event) =>
										event.report.attendees.find(
											(attendee) =>
												attendee.referenceId === record.key && attendee.status === "LATE"
										)
									);

									return (
										<Tooltip title="Total">
											{present.length + sick.length + excused.length + absent.length + late.length}
										</Tooltip>
									);
								},
							},
						],
					});
					// ADD SUMMARY PER SEMESTER

					if (semesterCols.length > 0) {
						cols.push({
							title: semester.name,
							dataIndex: semester._id,
							key: semester._id,
							align: "center",
							children: semesterCols,
							width: 40,
						});
					}
				}

				setColumns(cols);
			})
			.catch((error) => {
				if (error.response && error.response.data) {
					notification.error({
						message: "Kesalahan!",
						description: error.response.data.message,
					});
				} else {
					notification.error({
						message: "Kesalahan!",
						description: error.message,
					});
				}
			})
			.finally(() => {
				onLoading(false);
			});
	};

	const handleUnenrollStudent = (studentId) => {
		onLoading(true);
		requestUnenrollStudentsFromClassroom(classroom._id, {
			studentIds: [studentId],
		})
			.then((response) => {
				fetchClassroom();
			})
			.catch((error) => {
				if (error.response && error.response.data) {
					notification["error"]({
						message: "Kesalahan!",
						description: error.response.data.message,
					});
				} else {
					notification["error"]({
						message: "Kesalahan!",
						description: error.message,
					});
				}
			})
			.finally(() => {
				onLoading(false);
			});
	};

	const handleUnassignTeacherButton = (id, teacherId, lesson) => {
		onLoading(true);
		if (lesson) {
			requestUnassignTeacherFromLesson(id, { teacherIds: [teacherId] })
				.then((response) => {
					fetchClassroom();
				})
				.catch((error) => {
					if (error.response && error.response.data) {
						notification["error"]({
							message: "Kesalahan!",
							description: error.response.data.message,
						});
					} else {
						notification["error"]({
							message: "Kesalahan!",
							description: error.message,
						});
					}
				})
				.finally(() => {
					onLoading(false);
				});
		} else {
			requestUnassignTeacherFromClassroom(id, { teacherIds: [teacherId] })
				.then((response) => {
					fetchClassroom();
				})
				.catch((error) => {
					if (error.response && error.response.data) {
						notification["error"]({
							message: "Kesalahan!",
							description: error.response.data.message,
						});
					} else {
						notification["error"]({
							message: "Kesalahan!",
							description: error.message,
						});
					}
				})
				.finally(() => {
					onLoading(false);
				});
		}
	};

	const handleClose = () => {
		// form.resetFields();
		setClassroom(null);
		setMappedLessons([]);
		setMappedStudents([]);
		setLesson(null);
		onClose();
	};

	const handleEnrollStudentButton = () => {
		setStudentEnrollVisible(true);
	};

	const handleEnrollStudentClose = () => {
		setStudentEnrollVisible(false);
	};

	const handleEnrollStudentSuccess = () => {
		setStudentEnrollVisible(false);
		fetchClassroom();
	};

	const handleDailyReportClose = () => {
		setDailyReportVisible(false);
	};

	const handleDailyReportSuccess = () => {
		setDailyReportVisible(false);
		fetchClassroom();
	};

	const handleAssignTeacherButton = (lesson, type) => {
		setLesson(lesson);
		setAssignType(type);
		setTeacherAssignVisible(true);
	};

	const handleAssignTeacherClose = () => {
		setLesson(null);
		setTeacherAssignVisible(false);
	};

	const handleAssignTeacherSuccess = () => {
		setLesson(null);
		setTeacherAssignVisible(false);
		fetchClassroom();
	};

	const handleModeChange = (value) => {
		setMode(value);
	};

	const handlePromotionClose = () => {
		setPromotionFormVisible(false);
	};

	const handlePromotionSuccess = () => {
		setPromotionFormVisible(false);
	};

	const actionMenus = [
		{
			key: "PROMOTE",
			icon: <UpCircleFilled />,
			label: "Promosikan Siswa untuk Tahun Ajaran Baru",
		},
	];

	const actionMenuProps = {
		items: actionMenus,
		onClick: (e) => {
			if (e.key === "PROMOTE") {
				setPromotionFormVisible(true);
			}
		},
	};

	return (
		<>
			<TeacherAssignFormModal
				isVisible={isTeacherAssignVisible}
				isLoading={isLoading}
				onLoading={onLoading}
				onClose={handleAssignTeacherClose}
				onSuccess={handleAssignTeacherSuccess}
				classroom={assignType === "CLASSROOM" ? classroom : null}
				lesson={lesson}
			/>
			<StudentEnrollFormModal
				isVisible={isStudentEnrollVisible}
				isLoading={isLoading}
				onLoading={onLoading}
				onClose={handleEnrollStudentClose}
				onSuccess={handleEnrollStudentSuccess}
				classroom={classroom}
			/>
			<ClassroomDailyReportModal
				classroom={classroom}
				isVisible={isDailyReportVisible}
				onClose={handleDailyReportClose}
			/>
			<ClassroomPromotionFormModal
				isVisible={isPromotionFormVisible}
				isLoading={isLoading}
				onLoading={onLoading}
				onClose={handlePromotionClose}
				onSuccess={handlePromotionSuccess}
				classroom={classroom}
				studentIds={selectedStudentIds}
				activeYear={activeYear}
			/>
			{/* <ClassroomDailyReportFormModal
				isVisible={isDailyReportFormVisible}
				isLoading={isLoading}
				onLoading={onLoading}
				onClose={handleDailyReportClose}
				onSuccess={handleDailyReportSuccess}
				classroom={classroom}
				students={students}
			/> */}
			<Modal
				maskClosable={false}
				width={"calc(100% - 64px)"}
				style={{
					top: 32,
				}}
				title={"Detail Kelas"}
				open={isVisible}
				cancelText="Batal"
				onCancel={handleClose}
				footer={[
					<Button key={1} onClick={handleClose}>
						Tutup
					</Button>,
				]}>
				<Spin spinning={isLoading}>
					{classroom && (
						<Row gutter={{ xs: 8, sm: 8, md: 16, lg: 16 }}>
							<Col span={24}>
								<Space direction="horizontal">
									<Typography.Title level={3} style={{ margin: 0 }}>
										{classroom.name}
									</Typography.Title>
								</Space>
							</Col>
							<Col lg={24} md={24}>
								<Space
									direction="vertical"
									size="middle"
									className="layout-popup"
									style={{ width: "100%" }}>
									<Card title="Detail Kelas" size="small">
										<Descriptions layout="vertical" column={2}>
											<Descriptions.Item label="Tahun">{classroom.year.name}</Descriptions.Item>
											<Descriptions.Item label="Nama">{classroom.name}</Descriptions.Item>
											<Descriptions.Item label="Tingkat">{classroom.grade}</Descriptions.Item>
											<Descriptions.Item label="Wali Kelas">
												{/* wrap */}
												<Space wrap>
													{classroom.teachers.length > 0 &&
														classroom.teachers.map((teacher, index) => {
															return (
																<span key={index}>
																	{teacher.name}
																	<Tag
																		color="blue"
																		style={{ marginLeft: 4, marginRight: 0, fontWeight: "normal" }}>
																		{teacher.type === "GUIDANCE" ? "Wali Kelas" : "Asisten"}
																	</Tag>
																	<Popconfirm
																		placement="topRight"
																		title={`Anda yakin akan membebastugaskan ${teacher.name}?`}
																		onConfirm={(e) =>
																			handleUnassignTeacherButton(classroom._id, teacher._id)
																		}
																		okText="Yes"
																		cancelText="No">
																		<Button
																			type="link"
																			shape="default"
																			size="small"
																			icon={<CloseCircleFilled />}
																			danger
																		/>
																	</Popconfirm>
																</span>
															);
														})}
													<Button
														type="dashed"
														size="small"
														onClick={() =>
															handleAssignTeacherButton(
																lessons.length > 0 ? lessons[0] : null,
																"CLASSROOM"
															)
														}>
														<PlusCircleFilled /> Tambah
													</Button>
												</Space>
											</Descriptions.Item>
										</Descriptions>
									</Card>
									<Card title={"Aksi"} size="small">
										<Row gutter={[8, 8]}>
											<Col span={12}>
												<Button
													type="dashed"
													style={{ width: "100%" }}
													onClick={() => {
														setDailyReportVisible(true);
													}}>
													<HiClipboardDocumentCheck
														style={{
															marginRight: 4,
														}}
													/>{" "}
													Presensi Harian
												</Button>
											</Col>
											<Col span={12}>
												<Button
													type="dashed"
													style={{ width: "100%" }}
													onClick={() => {
														notification.warning({
															message: "Fitur belum tersedia",
															description: "Saat ini fitur masih dalam tahap pengembangan.",
														});
													}}>
													<HiClipboardDocumentList
														style={{
															marginRight: 4,
														}}
													/>{" "}
													Nilai Raport
												</Button>
											</Col>
										</Row>
									</Card>
									<Card title="Pelajaran" size="small">
										<Table
											size="small"
											columns={lessonColumns}
											dataSource={mappedLessons}
											loading={isLoading}
											bordered
											pagination={{
												position: "bottomRight",
												pageSizeOptions: ["10", "20", "50", "100"],
												showSizeChanger: true,
												defaultPageSize: 100,
												locale: { items_per_page: "" },
											}}
										/>
										<Typography.Text
											type="secondary"
											style={{
												display: "block",
											}}>
											Untuk dapat menambah atau menghapus pelajaran, silahkan ke menu
											<strong> pelajaran </strong>.
										</Typography.Text>
									</Card>
								</Space>
							</Col>
							<Col lg={24} md={24}>
								<Space
									direction="vertical"
									size="middle"
									className="layout-popup"
									style={{ width: "100%" }}>
									<Card
										title={
											<div
												style={{
													display: "flex",
													justifyContent: "space-between",
													alignItems: "center",
													gap: 8,
												}}>
												<span>Siswa</span>
												<Space wrap>
													<Radio.Group
														size="small"
														value={mode}
														onChange={(e) => handleModeChange(e.target.value)}
														style={{ float: "right" }}>
														<Radio.Button value="student">Siswa</Radio.Button>
														<Radio.Button value="invoice">Tagihan</Radio.Button>
														<Radio.Button value="attendance">Presensi</Radio.Button>
													</Radio.Group>
													{selectedStudentIds.length > 0 ? (
														<Dropdown.Button
															type="default"
															size="small"
															menu={actionMenuProps}
															trigger={["click"]}>
															<CheckCircleFilled /> {selectedStudentIds.length} Dipilih
														</Dropdown.Button>
													) : (
														<Button type="default" size="small" onClick={handleEnrollStudentButton}>
															<PlusCircleFilled /> Tambah
														</Button>
													)}
												</Space>
											</div>
										}
										size="small">
										<Table
											size="small"
											columns={columns}
											dataSource={mappedStudents}
											loading={isLoading}
											bordered
											pagination={{
												position: "bottomRight",
												pageSizeOptions: ["10", "20", "50", "100"],
												showSizeChanger: true,
												defaultPageSize: 100,
												locale: { items_per_page: "" },
											}}
											scroll={{ x: "max-content" }}
											rowSelection={{
												type: "checkbox",
												...{
													onChange: (selectedRowKeys, selectedRows) => {
														setSelectedStudentIds(selectedRowKeys.map((key) => key.toString()));
													},
												},
											}}
										/>
										{mode === "attendance" && (
											// download attendance, can be per month, per semester, or all, if per month, then show month picker, if per semester, then show semester picker
											<Space.Compact
												direction="horizontal"
												size={0}
												style={{
													width: "100%",
													display: "flex",
													justifyContent: "flex-end",
												}}>
												<Select
													style={{
														width: 120,
														borderTopRightRadius: 0,
														borderBottomRightRadius: 0,
													}}
													value={downloadMode}
													onChange={(value) => setDownloadMode(value)}>
													{/* <Select.Option value="all">Semua</Select.Option> */}
													<Select.Option value="month">Bulan</Select.Option>
													{/* <Select.Option value="semester">Semester</Select.Option> */}
												</Select>
												{downloadMode === "month" && (
													<Select
														style={{
															minWidth: 120,
															borderRadius: 0,
														}}
														defaultValue={dayjs().format("YYYY-MM")}
														value={downloadMonth}
														onChange={(value) => setDownloadMonth(value)}>
														{year.semesters
															.flatMap((semester) => {
																const monthInSemester =
																	dayjs(semester.endedAt).diff(semester.startedAt, "month") + 1;
																const semesterMonths = [];

																for (let i = 0; i < monthInSemester; i++) {
																	semesterMonths.push(
																		dayjs(semester.startedAt).add(i, "month").format("YYYY-MM")
																	);
																}

																return semesterMonths;
															})
															.map((month) => {
																return (
																	<Select.Option key={month} value={month}>
																		{month}
																	</Select.Option>
																);
															})}
													</Select>
												)}
												{downloadMode === "semester" && (
													<Select
														style={{
															minWidth: 120,
															borderRadius: 0,
														}}
														defaultValue={year.semesters[0]._id}
														// value={downloadSemester}
														// onChange={(value) => setDownloadSemester(value)}
													>
														{year.semesters.map((semester) => {
															return (
																<Select.Option key={semester._id} value={semester._id}>
																	{semester.name}
																</Select.Option>
															);
														})}
													</Select>
												)}
												<Button
													type="default"
													icon={<DownloadOutlined />}
													style={{
														borderTopLeftRadius: 0,
														borderBottomLeftRadius: 0,
													}}
													size="middle"
													onClick={async () => {
														const selectedMonth = downloadMonth;
														const eventsInMonth = events.filter(
															(event) => dayjs(event.startedAt).format("YYYY-MM") === selectedMonth
														);

														// HEADER_ROW from first day of month to last day of month
														const HEADER_ROW = [
															{
																value: "No",
																fontWeight: "bold",
															},
															{
																value: "Nama",
																fontWeight: "bold",
															},
														];
														const daysInMonth = dayjs(selectedMonth).daysInMonth();
														for (let i = 1; i <= daysInMonth; i++) {
															HEADER_ROW.push({
																value: i,
																fontWeight: "bold",
																align: "center",
															});
														}
														// ADD SUMMARY of each H, A, S, I, T
														HEADER_ROW.push({
															value: "H",
															fontWeight: "bold",
															align: "center",
														});
														HEADER_ROW.push({
															value: "S",
															fontWeight: "bold",
															align: "center",
														});
														HEADER_ROW.push({
															value: "I",
															fontWeight: "bold",
															align: "center",
														});
														HEADER_ROW.push({
															value: "A",
															fontWeight: "bold",
															align: "center",
														});
														HEADER_ROW.push({
															value: "T",
															fontWeight: "bold",
															align: "center",
														});
														HEADER_ROW.push({
															value: "Total",
															fontWeight: "bold",
															align: "center",
														});

														const DATA_ROW = mappedStudents.map((student, index) => {
															const row = [
																{
																	value: index + 1,
																},
																{
																	value: student.name,
																},
															];

															for (let i = 1; i <= daysInMonth; i++) {
																const event = eventsInMonth.find(
																	(event) =>
																		dayjs(event.startedAt).format("D") === i.toString() &&
																		event.report.attendees.find(
																			(attendee) => attendee.referenceId === student.key
																		)
																);

																if (event) {
																	const attendance = event.report.attendees.find(
																		(attendee) => attendee.referenceId === student.key
																	);

																	if (attendance) {
																		if (attendance.status === "PRESENT") {
																			row.push({
																				value: "H",
																				backgroundColor: "#52c41a",
																				align: "center",
																			});
																		} else if (attendance.status === "ABSENT") {
																			row.push({
																				value: "A",
																				backgroundColor: "#f5222d",
																				align: "center",
																			});
																		} else if (
																			attendance.status === "EXCUSED" &&
																			attendance?.permission?.type === "ILLNESS"
																		) {
																			row.push({
																				value: "S",
																				backgroundColor: "#1890ff",
																				align: "center",
																			});
																		} else if (
																			attendance.status === "EXCUSED" &&
																			attendance?.permission?.type === "LEAVE"
																		) {
																			row.push({
																				value: "I",
																				backgroundColor: "#722ed1",
																				align: "center",
																			});
																		} else if (attendance.status === "LATE") {
																			row.push({
																				value: "T",
																				backgroundColor: "#fa8c16",
																				align: "center",
																			});
																		} else {
																			row.push({
																				value: "-",
																				align: "center",
																			});
																		}
																	} else {
																		row.push({
																			value: "?",
																			align: "center",
																		});
																	}
																} else {
																	row.push({
																		value: "-",
																		align: "center",
																	});
																}
															}

															// ADD SUMMARY of each H, A, S, I, T
															const present = eventsInMonth.filter((event) =>
																event.report.attendees.find(
																	(attendee) =>
																		attendee.referenceId === student.key &&
																		attendee.status === "PRESENT"
																)
															);

															const illness = eventsInMonth.filter((event) =>
																event.report.attendees.find(
																	(attendee) =>
																		attendee.referenceId === student.key &&
																		attendee.status === "EXCUSED" &&
																		attendee?.permission?.type === "ILLNESS"
																)
															);

															const leave = eventsInMonth.filter((event) =>
																event.report.attendees.find(
																	(attendee) =>
																		attendee.referenceId === student.key &&
																		attendee.status === "EXCUSED" &&
																		attendee?.permission?.type === "LEAVE"
																)
															);

															const absent = eventsInMonth.filter((event) =>
																event.report.attendees.find(
																	(attendee) =>
																		attendee.referenceId === student.key &&
																		attendee.status === "ABSENT"
																)
															);

															const late = eventsInMonth.filter((event) =>
																event.report.attendees.find(
																	(attendee) =>
																		attendee.referenceId === student.key &&
																		attendee.status === "LATE"
																)
															);

															row.push({
																value: present.length,
																align: "center",
															});
															row.push({
																value: illness.length,
																align: "center",
															});
															row.push({
																value: leave.length,
																align: "center",
															});
															row.push({
																value: absent.length,
																align: "center",
															});
															row.push({
																value: late.length,
																align: "center",
															});
															row.push({
																value:
																	present.length +
																	illness.length +
																	leave.length +
																	absent.length +
																	late.length,
																align: "center",
															});
															return row;
														});

														const data = [HEADER_ROW, ...DATA_ROW];

														const buffer = await writeXlsxFile(data, {
															columns: [
																{ width: 5 },
																{ width: 20 },
																...Array.from({ length: daysInMonth }, () => ({
																	width: 5,
																})),
																{ width: 5 },
																{ width: 5 },
																{ width: 5 },
																{ width: 5 },
																{ width: 5 },
																{ width: 8 },
															],
															buffer: true,
														});

														const blob = new Blob([buffer], {
															type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
														});

														const url = URL.createObjectURL(blob);
														const a = document.createElement("a");

														// download
														a.href = url;
														a.download = `Presensi_${classroom.name}_${selectedMonth}.xlsx`;
														a.click();
													}}>
													Unduh Presensi
												</Button>
											</Space.Compact>
										)}
									</Card>
								</Space>
							</Col>
						</Row>
					)}
				</Spin>
			</Modal>
		</>
	);
};

export default ClassroomDetailModal;
