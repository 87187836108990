import axios from "axios";

// CURRICULUM
export async function requestGetCurriculumList({
	page = 1,
	limit = 10,
	order = "name:desc",
	keyword = "",
	filter = "",
}) {
	return await axios.get(
		`${
			process.env.REACT_APP_API_URL
		}/teacher/curriculums?order=${order}&page=${page}&limit=${limit}&keyword=${encodeURIComponent(
			keyword
		)}&filter=${filter}`
	);
}
// CURRICULUM

// YEAR
export async function requestGetYearList({
	page = 1,
	limit = 10,
	order = "name:desc",
	keyword = "",
	filter = "",
}) {
	return await axios.get(
		`${
			process.env.REACT_APP_API_URL
		}/teacher/years?order=${order}&page=${page}&limit=${limit}&keyword=${encodeURIComponent(
			keyword
		)}&filter=${filter}`
	);
}

export async function requestGetActiveYear() {
	return await axios.get(`${process.env.REACT_APP_API_URL}/teacher/years/active`);
}
// YEAR

// CLASSROOM
export async function requestGetClassroomList({
	page = 1,
	limit = 10,
	order = "name:desc",
	keyword = "",
	filter = "",
}) {
	return await axios.get(
		`${
			process.env.REACT_APP_API_URL
		}/teacher/classrooms?order=${order}&page=${page}&limit=${limit}&keyword=${encodeURIComponent(
			keyword
		)}&filter=${filter}`
	);
}

export async function requestShowClassroom(id) {
	return await axios.get(`${process.env.REACT_APP_API_URL}/teacher/classrooms/${id}`);
}
// CLASSROOM

// LESSON
export async function requestGetLessonList({
	page = 1,
	limit = 10,
	order = "name:desc",
	keyword = "",
	filter = "",
}) {
	return await axios.get(
		`${
			process.env.REACT_APP_API_URL
		}/teacher/lessons?order=${order}&page=${page}&limit=${limit}&keyword=${encodeURIComponent(
			keyword
		)}&filter=${filter}`
	);
}

export async function requestShowLesson(id) {
	return await axios.get(`${process.env.REACT_APP_API_URL}/teacher/lessons/${id}`);
}

export async function requestScheduleLesson(id, data) {
	return await axios.post(`${process.env.REACT_APP_API_URL}/teacher/lessons/${id}/schedule`, data);
}

export async function requestEditLesson(id, data) {
	return await axios.put(`${process.env.REACT_APP_API_URL}/teacher/lessons/${id}/edit`, data);
}
// LESSON

// STUDENT
export async function unenrollStudents(data) {
	return await axios.post(`${process.env.REACT_APP_API_URL}/teacher/students/unenroll`, data);
}
// STUDENT

// EVENT
export async function requestGetEventList({
	page = 1,
	limit = 10,
	order = "_id:desc",
	keyword = "",
	filter = "",
}) {
	return await axios.get(
		`${
			process.env.REACT_APP_API_URL
		}/teacher/events?order=${order}&page=${page}&limit=${limit}&keyword=${encodeURIComponent(
			keyword
		)}&filter=${filter}`
	);
}

export async function requestShowEvent(id) {
	return await axios.get(`${process.env.REACT_APP_API_URL}/teacher/events/${id}`);
}

export async function requestAddEvent(data) {
	return await axios.post(`${process.env.REACT_APP_API_URL}/teacher/events/add`, data);
}

export async function requestReportEvent(id, data) {
	return await axios.post(`${process.env.REACT_APP_API_URL}/teacher/events/${id}/report`, data);
}
// EVENT

// REPORT-ASSESSMENT
export async function requestGetReportAssessmentList({
	page = 1,
	limit = 10,
	order = "_id:desc",
	keyword = "",
	filter = "",
}) {
	return await axios.get(
		`${
			process.env.REACT_APP_API_URL
		}/teacher/@report-module/report-assessments?order=${order}&page=${page}&limit=${limit}&keyword=${encodeURIComponent(
			keyword
		)}&filter=${filter}`
	);
}
// REPORT-ASSESSMENT

// REPORT-CARD
export async function requestGetReportCardList({
	page = 1,
	limit = 10,
	order = "_id:desc",
	keyword = "",
	filter = "",
}) {
	return await axios.get(
		`${
			process.env.REACT_APP_API_URL
		}/teacher/@report-module/report-cards?order=${order}&page=${page}&limit=${limit}&keyword=${encodeURIComponent(
			keyword
		)}&filter=${filter}`
	);
}

export async function requestGenerateReportCards(data) {
	return await axios.post(
		`${process.env.REACT_APP_API_URL}/teacher/@report-module/report-cards/generate`,
		data
	);
}

export async function requestShowReportCard(id) {
	return await axios.get(
		`${process.env.REACT_APP_API_URL}/teacher/@report-module/report-cards/${id}`
	);
}

export async function requestSignReportCard(id, data) {
	return await axios.post(
		`${process.env.REACT_APP_API_URL}/teacher/@report-module/report-cards/${id}/sign`,
		data
	);
}
// REPORT-CARD

// REPORT-MARK
export async function requestGetReportMarkList({
	page = 1,
	limit = 10,
	order = "_id:desc",
	keyword = "",
	filter = "",
}) {
	return await axios.get(
		`${
			process.env.REACT_APP_API_URL
		}/teacher/@report-module/report-marks?order=${order}&page=${page}&limit=${limit}&keyword=${encodeURIComponent(
			keyword
		)}&filter=${filter}`
	);
}

export async function requestEditReportMark(id, data) {
	return await axios.put(
		`${process.env.REACT_APP_API_URL}/teacher/@report-module/report-marks/${id}/edit`,
		data
	);
}

export async function requestSignReportMarks(data) {
	return await axios.post(
		`${process.env.REACT_APP_API_URL}/teacher/@report-module/report-marks/sign`,
		data
	);
}
// REPORT-MARK
