import React, { useEffect } from "react";

const TawkToChat = () => {
	useEffect(() => {
		const Tawk_API = window.Tawk_API || {};
		const Tawk_LoadStart = new Date();

		const script = document.createElement("script");
		script.async = true;
		script.src = "https://embed.tawk.to/670e59fe2480f5b4f58dad96/1ia80v2u7";
		script.charset = "UTF-8";
		script.setAttribute("crossorigin", "*");

		const firstScriptTag = document.getElementsByTagName("script")[0];
		firstScriptTag.parentNode.insertBefore(script, firstScriptTag);

		return () => {
			document.head.removeChild(script); // Clean up on unmount
		};
	}, []); // Run only once when the component mounts

	return null; // No visual UI
};

export default TawkToChat;
