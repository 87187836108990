/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import {
	ExclamationCircleFilled,
	FileExcelFilled,
	MoreOutlined,
	PlusCircleFilled,
	QuestionCircleOutlined,
} from "@ant-design/icons";
import {
	App,
	Button,
	Card,
	Dropdown,
	Segmented,
	Space,
	Table,
	Tag,
	Tooltip,
	Typography,
	theme,
} from "antd";
import { useEffect, useState } from "react";
import {
	deleteStudent,
	requestGetClassroomList,
	requestGetStudentList,
	requestGetYearList,
} from "../../../services/admin.service";
import StudentFormModal from "./components/StudentFormModal";
import dayjs from "dayjs";
import StudentImportFormModal from "./components/StudentImportFormModal";
import { YearFilter } from "../../../components/YearFilter";
import { useContext } from "react";
import AppContext from "../../../store/app.context.tsx";
import SearchInput from "../../../components/SearchInput";
import { HiOutlineEye, HiOutlinePencil, HiOutlineTrash } from "react-icons/hi2";
import StudentDetailModal from "./components/StudentDetailModal";
import { useSearchParams } from "react-router-dom";

const { useToken } = theme;

const columns = [
	{
		title: "No.",
		dataIndex: "number",
		key: "number",
		width: 36,
		render: (text, record, index) => {
			return index + 1;
		},
	},
	{
		title: "NISN",
		dataIndex: "number",
		key: "number",
		sorter: true,
		render: (text, record) => {
			return (
				<Typography.Text
					style={{
						fontFamily: "monospace",
						fontWeight: "bold",
						whiteSpace: "nowrap",
					}}
					copyable>
					{text}
				</Typography.Text>
			);
		},
	},
	{
		title: "Nama",
		dataIndex: "name",
		key: "name",
	},
	{
		title: "TTL",
		dataIndex: "birthDate",
		key: "birthDate",
	},
	{
		title: "Jenis Kelamin",
		dataIndex: "gender",
		key: "gender",
		filters: [
			{
				text: "Laki-laki",
				value: "MALE",
			},
			{
				text: "Perempuan",
				value: "FEMALE",
			},
		],
	},
	// {
	// 	title: "Alamat",
	// 	dataIndex: "address",
	// 	key: "address",
	// },
	{
		title: "Kelas",
		dataIndex: "classroom",
		key: "classroom",
	},
	{
		title: (
			<>
				<span className="d-block">Status</span>
				<Tooltip title="Status aktif menandakan siswa terdaftar di kelas pada tahun akademik aktif sehingga dapat mengakses aplikasi.">
					<span className="d-block text-xs">
						<QuestionCircleOutlined style={{ marginLeft: 4 }} />
					</span>
				</Tooltip>
			</>
		),
		dataIndex: "status",
		key: "status",
		filters: [
			{
				text: "Aktif",
				value: "ACTIVE",
			},
			{
				text: "Tidak Aktif",
				value: "INACTIVE",
			},
		],
		render: (text, record) => {
			if (text === "ACTIVE") {
				return <Tag color="green">AKTIF</Tag>;
			} else {
				return <Tag color="red">TDK AKTIF</Tag>;
			}
		},
	},
	{
		title: "Aksi",
		dataIndex: "actions",
		key: "actions",
		align: "right",
	},
];

const otherButtons = [
	{
		key: "show",
		label: "Lihat",
		icon: <HiOutlineEye />,
	},
	{
		key: "edit",
		label: "Ubah",
		icon: <HiOutlinePencil />,
	},
	{
		type: "divider",
	},
	{
		key: "delete",
		label: "Hapus",
		icon: <HiOutlineTrash />,
		danger: true,
	},
];

const StudentPage = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const context = useContext(AppContext);
	const { token } = useToken();
	const { notification, modal } = App.useApp();

	// SEARCH, FILTER, PAGINATION
	const [keyword, setKeyword] = useState("");
	const [filter, setFilter] = useState({
		startedAt: dayjs().startOf("month").format("YYYY-MM-DD"),
		endedAt: dayjs().endOf("month").format("YYYY-MM-DD"),
	});
	const [sorter, setSorter] = useState({
		name: "asc",
	});
	const [total, setTotal] = useState(0);
	const [page, setPage] = useState(1);
	const [limit, setLimit] = useState(10);

	const handleSearch = (value) => {
		setKeyword(value.trim());
	};

	const handlePaginate = (pagination, filters, sorter) => {
		setPage(pagination.current);
		setLimit(pagination.pageSize);

		// UPDATE FILTER
		if (filters) {
			const additionalFilter = {};
			Object.keys(filters).forEach((key) => {
				if (filters[key]) {
					additionalFilter[key] = filters[key].join("|");
				} else {
					additionalFilter[key] = "";
				}
			});

			setFilter({
				...filter,
				...additionalFilter,
			});
		} else {
			const tempFilter = {};
			// only allow name
			Object.keys(filter).forEach((key) => {
				if (key === "name") {
					tempFilter[key] = filter[key];
				}
			});

			setFilter(tempFilter);
		}

		// UPDATE SORTER
		if (sorter && sorter.field) {
			setSorter({
				[sorter.field]: sorter.order === "ascend" ? "asc" : "desc",
			});
		} else {
			setSorter({
				name: "asc",
			});
		}
	};
	// SEARCH, FILTER, PAGINATION

	const [years, setYears] = useState([]);
	const [classrooms, setClassrooms] = useState([]);
	const [students, setStudents] = useState([]);
	const [mappedStudents, setMappedStudents] = useState([]);
	const [isLoading, setLoading] = useState(false);
	const [selectedYear, setSelectedYear] = useState(null);
	const [selectedYearId, setSelectedYearId] = useState("");
	const [selectedClassroom, setSelectedClassroom] = useState("");
	const [selectedClassroomId, setSelectedClassroomId] = useState("");
	const [filteredBy, setFilteredBy] = useState("");

	const [student, setStudent] = useState(null);
	const [isStudentFormVisible, setStudentFormVisible] = useState(false);
	const [isStudentImportFormVisible, setStudentImportFormVisible] = useState(false);
	const [isDetailVisible, setDetailVisible] = useState(false);

	useEffect(() => {
		setSelectedYearId(context.year._id);
		setFilter({
			...filter,
			year: context.year._id,
		});
		fetchYearList();
	}, []);

	useEffect(() => {
		setSearchParams({ keyword: keyword, page: page, limit: limit });

		if (filter.year) {
			fetchStudentList();
			fetchClassroomList(filter.year);
		}
	}, [keyword, filter, page, limit]);

	useEffect(() => {
		if (searchParams.has("keyword")) {
			setKeyword(searchParams.get("keyword"));
		}
		if (searchParams.has("page")) {
			setPage(parseInt(searchParams.get("page")));
		}
		if (searchParams.has("limit")) {
			setLimit(parseInt(searchParams.get("limit")));
		}
	}, []);

	useEffect(() => {
		if (selectedYearId !== "") {
			const year = years.find((year) => year._id === selectedYearId);
			if (year) {
				setSelectedYear(year);
				setFilteredBy(year.name);
			} else {
				setFilteredBy(context.year.name);
			}
		} else {
			setFilteredBy("");
		}
		// handleFilter();
		setFilter({
			...filter,
			year: selectedYearId,
			classroom: selectedClassroomId,
		});
	}, [selectedYearId, selectedClassroomId]);

	const fetchYearList = () => {
		setLoading(true);
		requestGetYearList({
			page: 1,
			limit: 100,
		})
			.then((response) => {
				setYears(response.data.data);
			})
			.catch((error) => {})
			.finally(() => {
				setLoading(false);
			});
	};

	const fetchClassroomList = (yearId) => {
		setLoading(true);
		requestGetClassroomList({
			page: 1,
			limit: 1000,
			filter: `year:${yearId}`,
			order: "grade:asc,name:asc",
		})
			.then((response) => {
				setClassrooms(response.data.data);
			})
			.catch((error) => {
				if (error.response && error.response.data) {
					notification.error({
						message: "Kesalahan!",
						description: error.response.data.message,
					});
				} else {
					notification.error({
						message: "Kesalahan!",
						description: error.message,
					});
				}
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const fetchStudentList = () => {
		setLoading(true);

		let tempFilter = "";
		if (filter) {
			tempFilter = Object.keys(filter)
				.map((key) => `${key}:${filter[key]}`)
				.filter(
					(f) => f.split(":")[1] !== "" && f.split(":")[1] !== undefined && f.split(":")[1] !== null
				)
				.join(",");
		}

		let tempSorter = "";
		if (sorter) {
			tempSorter = Object.keys(sorter)
				.map((key) => `${key}:${sorter[key]}`)
				.filter(
					(s) => s.split(":")[1] !== "" && s.split(":")[1] !== undefined && s.split(":")[1] !== null
				)
				.join(",");
		}

		requestGetStudentList({
			page: page,
			limit: limit,
			order: tempSorter ?? "name:asc",
			keyword: keyword ?? "",
			filter: tempFilter ?? "",
		})
			.then((response) => {
				setStudents(response.data.data);
				updateMappedStudent(response.data.data);
				setTotal(response.data.pagination.total);
			})
			.catch((error) => {
				if (error.response && error.response.data) {
					notification.error({
						message: "Kesalahan!",
						description: error.response.data.message,
					});
				} else {
					notification.error({
						message: "Kesalahan!",
						description: error.message,
					});
				}
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const updateMappedStudent = (data) => {
		setMappedStudents(
			data.map((d) => {
				return {
					key: d._id,
					number: d.number,
					name: d.name,
					birthDate: `${d.birthPlace}, ${dayjs(d.birthDate).format("DD MMMM YYYY")}`,
					gender: d.gender === "MALE" ? "Laki-laki" : "Perempuan",
					address: d.address,
					classroom: d?.classroom?.name || "-",
					status: d.status,
					actions: (
						<Space>
							<Dropdown
								trigger={["click"]}
								menu={{
									onClick: (e) => {
										if (e.key === "show") {
											handleShowButton(d);
										} else if (e.key === "edit") {
											handleEditButton(d);
										} else if (e.key === "delete") {
											modal.confirm({
												title: "Sebentar",
												icon: <ExclamationCircleFilled />,
												content:
													"Anda yakin akan menghapus siswa ini? Tindakan ini tidak dapat dibatalkan. Semua data terkait akan terputus.",
												okText: "Ya",
												okType: "danger",
												cancelText: "Tidak",
												onOk() {
													handleDeleteButton(d._id);
												},
												onCancel() {},
											});
										}
									},
									items: otherButtons,
								}}
								placement="bottomRight"
								arrow>
								<Button type="default" shape="circle" size="small" icon={<MoreOutlined />} />
							</Dropdown>
						</Space>
					),
				};
			})
		);
	};

	const handleAddButton = () => {
		setStudentFormVisible(true);
	};

	const handleImportButton = () => {
		setStudentImportFormVisible(true);
	};

	const handleShowButton = (student) => {
		setStudent(student);
		setDetailVisible(true);
	};

	const handleEditButton = (student) => {
		setStudent(student);
		setStudentFormVisible(true);
	};

	const handleDeleteButton = (id) => {
		setLoading(true);
		deleteStudent(id)
			.then((response) => {
				if (response.data.code === 200) {
					notification["success"]({
						message: "Good job!",
						description: response.data.message,
					});

					fetchStudentList();
				}
			})
			.catch((error) => {
				if (error.response && error.response.data) {
					notification.error({
						message: "Kesalahan!",
						description: error.response.data.message,
					});
				} else {
					notification.error({
						message: "Kesalahan!",
						description: error.message,
					});
				}
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const handleOnCloseStudentForm = () => {
		setStudent(null);
		setStudentFormVisible(false);
	};

	const handleOnSuccessStudentForm = () => {
		setStudent(null);
		setStudentFormVisible(false);
		fetchStudentList();
	};

	const handleOnCloseStudentImportForm = () => {
		setStudentImportFormVisible(false);
	};

	const handleOnSuccessStudentImportForm = () => {
		setStudentImportFormVisible(false);
		fetchStudentList();
	};

	const handleOnCloseDetail = () => {
		setStudent(null);
		setDetailVisible(false);
	};

	return (
		<>
			<StudentFormModal
				isLoading={isLoading}
				isVisible={isStudentFormVisible}
				onLoading={(v) => setLoading(v)}
				student={student}
				onClose={handleOnCloseStudentForm}
				onSuccess={handleOnSuccessStudentForm}
				selectedYear={selectedYear}
			/>
			<StudentImportFormModal
				isLoading={isLoading}
				isVisible={isStudentImportFormVisible}
				onLoading={(v) => setLoading(v)}
				onClose={handleOnCloseStudentImportForm}
				onSuccess={handleOnSuccessStudentImportForm}
			/>
			<StudentDetailModal
				isLoading={isLoading}
				isVisible={isDetailVisible}
				onLoading={(v) => setLoading(v)}
				studentId={student ? student._id : null}
				onClose={handleOnCloseDetail}
			/>
			<Card
				bordered={false}
				title="Daftar Siswa"
				extra={
					<Space>
						<SearchInput
							placeholder="NISN atau nama siswa"
							onSearch={handleSearch}
							defaultValue={keyword}
							isLoading={isLoading}
						/>

						<YearFilter
							activeYear={context.year}
							years={years}
							filteredBy={{
								key: selectedYearId,
								value: filteredBy,
							}}
							filterBy={(key) => {
								setSelectedYearId(key);
							}}
						/>
						<Button icon={<PlusCircleFilled />} type="default" onClick={handleAddButton}>
							Tambah
						</Button>
						<Button icon={<FileExcelFilled />} type="primary" onClick={handleImportButton}>
							<span className="ant-btn-text">Impor</span>
						</Button>
					</Space>
				}>
				<div>
					<Space direction="vertical">
						<Segmented
							size="large"
							options={[
								{ label: "Semua", value: "" },
								...classrooms.reduce((acc, classroom) => {
									if (!acc.find((item) => item.value === classroom.grade)) {
										acc.push({
											label: classroom.grade,
											value: classroom.grade,
										});
									}
									return acc;
								}, []),
							]}
							onChange={(e) => setSelectedClassroom(e)}
						/>
						<Segmented
							size="large"
							options={[
								{ label: "Semua", value: "" },
								...classrooms
									.filter(
										(classroom) => classroom.grade === selectedClassroom || !selectedClassroom
									)
									.map((classroom) => {
										return {
											label: classroom.name,
											value: classroom._id,
										};
									}),
								{ label: "Tidak terdaftar", value: "null" },
							]}
							onChange={(e) => {
								setSelectedClassroomId(e);
							}}
						/>
					</Space>
					<Table
						style={{
							marginTop: 16,
						}}
						bordered
						columns={columns}
						loading={isLoading}
						dataSource={mappedStudents}
						pagination={{
							current: page,
							total: total,
							position: "bottomRight",
							pageSizeOptions: ["10", "20", "50", "100"],
							showSizeChanger: true,
							locale: { items_per_page: "item/hal" },
						}}
						onChange={handlePaginate}
					/>
				</div>
			</Card>
		</>
	);
};

export default StudentPage;
